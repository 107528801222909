<template>
  <b-card>
    <ol
      class="c6 lst-kix_list_12-1 start"
      start="1"
    >
      <li class="c14 li-bullet-2">
        <h2
          id="h.49x2ik5"
          style="display:inline"
        >
          <span class="c1">Tambah Permintaan Data</span>
        </h2>
      </li>
    </ol>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 151.18px; height: 293.56px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image44.png')"
          style="width: 151.18px; height: 293.56px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c26 c12">
      <span class="c4">Untuk mengakses menu pengajuan, user harus memilih menu pengajuan yang ada pada bilah sidebar yang berada pada sisi kiri layar, lalu user akan diarahkan ke form untuk mengisi semua informasi yang dibutuhkan untuk Permintaan Data seperti yang ditampilkan pada gambar dibawah ini:</span>
    </p>
    <p class="c5 c13">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 672.00px; height: 310.65px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image47.png')"
          style="width: 672.00px; height: 310.65px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c7">
      <span class="c4" />
    </p>
    <p class="c19 c12">
      <span class="c4">Setelah muncul halaman Tulis Pengajuan, selanjutnya yaitu mengisi semua informasi yang dibutuhkan, jika sudah lengkap maka simpan dengan meng-klik tombol simpan yang ada pada pojok kiri bawah seperti yang ditandai pada kotak berwarna merah.</span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <ol
      class="c6 lst-kix_list_12-1"
      start="2"
    >
      <li class="c14 li-bullet-1">
        <h2
          id="h.2p2csry"
          style="display:inline"
        >
          <span class="c1">Daftar Permintaan Data.</span>
        </h2>
      </li>
    </ol>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 151.18px; height: 293.56px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image48.png')"
          style="width: 151.18px; height: 293.56px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c2 c12">
      <span class="c4" />
    </p>
    <p class="c19 c12">
      <span>Untuk melihat daftar permintaan data yang telah dibuat sebelumnya, user bisa mengaksesnya dengan memilih menu </span><span class="c8">daftar permintaan</span><span class="c4">, maka setelah menu tersebut di-klik maka akan muncul tampilan seperti dibawah ini.</span>
    </p>
    <p class="c2 c12">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 286.51px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image50.png')"
          style="width: 709.80px; height: 286.51px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <hr style="page-break-before:always;display:none;">
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <ol
      class="c6 lst-kix_list_12-1"
      start="3"
    >
      <li class="c14 li-bullet-2">
        <h2
          id="h.147n2zr"
          style="display:inline"
        >
          <span class="c1">Detail Permintaan Data.</span>
        </h2>
      </li>
    </ol>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c19 c12">
      <span>Setelah kita melewati tahap memilih menu </span><span class="c8">daftar permintaan, </span><span class="c4">kita bisa memilih salah satu data yang ingin kita lihat detailnya lebih lanjut, dengan cara mengklik No.Surat sperti yang di beri tanda berwarna merah seperti pada gambar dibawah ini.</span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 215.25px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image52.png')"
          style="width: 709.80px; height: 215.25px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c7">
      <span class="c4" />
    </p>
    <p class="c19 c12">
      <span>Setelah salah satu nomor surat di klik maka pengguna diarahkan pada halaman detail </span><span class="c8">Form Pengajuan, </span><span class="c4">pada halaman ini kita bisa melihat detail dari permintaan data yang telah ditambahkan sebelumnya.</span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 318.08px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image54.png')"
          style="width: 709.80px; height: 318.08px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 97.27px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image56.png')"
          style="width: 709.80px; height: 97.27px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <ol
      class="c6 lst-kix_list_12-1"
      start="4"
    >
      <li class="c14 li-bullet-1">
        <h2
          id="h.3o7alnk"
          style="display:inline"
        >
          <span class="c1">Edit Permintaan Data.</span>
        </h2>
      </li>
    </ol>
    <p class="c2 c12">
      <span class="c4" />
    </p>
    <p class="c19 c12">
      <span class="c4">Jika diperlukan pengubahan data pada dokumen permintaan data, user bisa melakukan pengeditan dokumen tersebut dengan 2 cara yaitu melalui halaman Daftar Permintaan Data dan halaman Detail Form Pengajuan, berikut langkah-langkah nya.</span>
    </p>
    <p class="c2 c12">
      <span class="c4" />
    </p>
    <h3
      id="h.23ckvvd"
      class="c39"
    >
      <span class="c1">4.4.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Akses Menu Edit Melalui Daftar Permintaan Data</span>
    </h3>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 1.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 157.41px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image37.png')"
          style="width: 709.80px; height: 208.42px; margin-left: -0.00px; margin-top: -31.85px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c19 c24">
      <span class="c4">Edit Melalui Daftar Perintah bisa dilakukan dengan langkah-langkah sebagai berikut:</span>
    </p>
    <p class="c19 c24">
      <span>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mengarahkan pointer mouse kearah kanan dan meng-klik symbol </span><span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 1.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 19.50px; height: 18.19px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image9.png')"
          style="width: 1563.93px; height: 649.66px; margin-left: -1481.21px; margin-top: -557.84px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span><span class="c4">&nbsp;atau titik-titik vertical. </span>
    </p>
    <p class="c18 c24">
      <span>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lalu mengklik dilanjutkan dengan meng-klik perintah </span><span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 1.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 63.65px; height: 25.05px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image9.png')"
          style="width: 856.00px; height: 355.13px; margin-left: -779.43px; margin-top: -277.54px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span><span class="c4">&nbsp;atau edit.</span>
    </p>
    <p class="c7">
      <span class="c4" />
    </p>
    <h3
      id="h.ihv636"
      class="c39"
    >
      <span class="c1">4.4.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Akses Menu Edit Melalui Halaman Detail Permaintaan Data</span>
    </h3>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c19 c24">
      <span class="c4">Jika anda sudah login sebagai Staff SE maka anda akan menemukan tombol Edit Permintaan Data pada pojok kanan atas seperti pada gambar dibawah yang diberi tanda kotak berwarna merah dibawah ini.</span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 317.83px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image18.png')"
          style="width: 709.80px; height: 317.83px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <ol
      class="c6 lst-kix_list_12-1"
      start="5"
    >
      <li class="c14 li-bullet-1">
        <h2
          id="h.32hioqz"
          style="display:inline"
        >
          <span class="c1">Ubah Status Permintaan Data</span>
        </h2>
      </li>
    </ol>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c19 c12">
      <span>Untuk mengubah status permintaan data, kita bisa mengakses fitur ubah status tersebut dari halaman </span><span class="c8">Form Edit Pengajuan</span><span>. Pada halaman tersebut kita bisa menemukan perintah tersebut dengan melakukan scroll ke paling bawah halaman dan berada pada sis kiri halaman tersebut, berikut contoh untuk mengubah status perintah dari </span><span class="c8">Open</span><span>&nbsp;menjadi </span><span class="c8">Close</span>
    </p>
    <p class="c10 c13">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 453.54px; height: 99.00px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image20.png')"
          style="width: 453.54px; height: 99.00px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 453.54px; height: 98.03px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image22.png')"
          style="width: 453.54px; height: 98.03px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 453.54px; height: 99.48px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image24.png')"
          style="width: 453.54px; height: 99.48px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c19 c32">
      <span class="c4">1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Setelah menemukan seksi atau bilah Status Permintaan Data, hal pertama yang dilakukan adalah mengarahkan poiner mouse ke form status yang ditunjukkan pada kotak no.1, lalu kilk.</span>
    </p>
    <p class="c19 c32">
      <span>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Selanjutnya akan tampil status perintah yang bisa dipilih seperti yang ditunjukkan pada kotak no.2, maka kita pilih yang </span><span class="c11 c8">close.</span>
    </p>
    <p class="c19 c32">
      <span class="c4">3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Langkah terakhir yaitu menekan tombol simpan yang ditunjukkan pada kotak no.3.</span>
    </p>
    <p class="c7">
      <span class="c4" />
    </p>
  </b-card>
</template>

<script>
import { BImg, BCard } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BCard,
  },
}
</script>
